import '~/images/mfi-logo.png';
import { createApp } from 'vue';
import store from '~/store';
import Components from '~/components/all';
import Directives from '~/directives/all';
import config from '~/app/config';

document.addEventListener("DOMContentLoaded", () => {
  const appElement = document.querySelector("#app");

  if (appElement) {
    // Create Vue instance
    const app = createApp(config);

    // // Use Vuex store
    app.use(store);

    // // Register global components
    for (const key in Components) {
      app.component(key, Components[key]);
    }

    // // Register global directives
    for (const dir in Directives) {
      app.directive(dir, Directives[dir]);
    }

    // Ensure Vue only enhances without wiping content
    app.mount("#app", true);
  } else {
    console.warn("No #app element found.");
  }
});
